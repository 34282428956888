import Axios from "../config/axios";

export default {
  getAll() {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/fields")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getById(id) {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/fields/" + id + "/show")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  delete(id) {
    return new Promise((resolve, reject) => {
      Axios.delete("/admin/fields/" + id + "/delete")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  create(data) {
    return new Promise((resolve, reject) => {
      Axios.post("/admin/fields/create", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createPricing(data) {
    return new Promise((resolve, reject) => {
      Axios.post("/admin/subscription-pricings/create", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updatePricing(id, data) {
    return new Promise((resolve, reject) => {
      Axios.put("/admin/subscription-pricings/" + id + "/update", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  update(id, data) {
    return new Promise((resolve, reject) => {
      Axios.put("/admin/fields/" + id + "/update", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateProperty(id, data) {
    return new Promise((resolve, reject) => {
      Axios.put("/admin/fields/" + id + "/update-property", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateLandOwner(fieldLandId, fieldId, data) {
    return new Promise((resolve, reject) => {
      Axios.put(
        "/admin/fields/" + fieldId + "/lands/" + fieldLandId + "/update-owner",
        data
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
